$(document).ready(function() {
    $('.photo').magnificPopup({
        type: 'image',
        gallery: {
            enabled: true
        }
    });

    var owl = $('.owl-carousel');

    owl.owlCarousel({
        loop: true,
        margin: 10,
        nav: false,
        items: 1,
        autoplay:true,
        autoplayTimeout:5000
    });

    owl.on('changed.owl.carousel', function(event) {
        $('#slider .nav-slider .col').removeClass('active');
        $('#slider .nav-slider .col:nth-child('+(event.item.index-1)+')').addClass('active');
    });

    $('.nav-slider .col').click(function() {
        $('.owl-carousel').trigger('to.owl.carousel', $(this).attr('data-owl-step'));
    });

    /* START menu for tablets and mobiles */
    $("#head a.mobile-btn").click(function() {
        if($('#head .nav').hasClass('active')){
            $('#head .nav').removeClass('active');
            $(this).removeClass('active');
            $(this).find('i.icon').addClass('icon-bars').removeClass('icon-cross');
        }else{
            $('#head .nav').addClass('active');
            $(this).addClass('active');
            $(this).find('i.icon').removeClass('icon-bars').addClass('icon-cross');
        }
    });
    /* END menu for tablets and mobiles*/

    /* START checkbox vypis vozu */
    $('#filter .form-input .btn-checkbox').click(function() {
        val = $(this).find('input').val();
        if(val == ""){
            $(this).addClass('active');
            $(this).find('input').attr('value',1);
        }else{
            $(this).removeClass('active');
            $(this).find('input').attr('value','');
        }
    });


    /* START filter range */
    $("#slide-1,#slide-2,#slide-3").slider();

    $("#slide-1,#slide-2,#slide-3").on("slide", function(slideEvt) {
        val = $(this).val().split(',');
        str = $(this).attr('data-str');
        type = $(this).attr('data-str-type');
    	$('#'+$(this).attr('data-target-text')).text(numberFormat(val[0],str,type)+' - '+numberFormat(val[1],str,type));
    });

    $("#slide-1,#slide-2,#slide-3").change(function(slideEvt) {
        val = $(this).val().split(',');
        str = $(this).attr('data-str');
        type = $(this).attr('data-str-type');
    	$('#'+$(this).attr('data-target-text')).text(numberFormat(val[0],str,type)+' - '+numberFormat(val[1],str,type));
    });
    /* END filter range*/
    $( window ).resize(function() {
        resizeUlDetail(true);
    });
    $(document).scroll(function() {
        constWidth = 990;
        /* FIX left column detail car*/
        if($(window).width() >= constWidth){
            $('.fix').css('width',$('.fix').css('width'));
            fixBlockHeight  = $('.fix').height();

            if($('#detail-car').height() < $(window).height()){
                downCorner      = ($(window).height()-(fixBlockHeight+$('#head').height()+57));
                console.log(downCorner + "-" +$(document).scrollTop() + " - " + $('#head').height());
                if($(document).scrollTop() > 99  && $(document).scrollTop() < downCorner){
                    $('.fix').addClass('active').removeClass('absolute').css('top','');
                }
                else if($(document).scrollTop() > 99 && $(document).scrollTop() > downCorner){
                    $('.fix').removeClass('active').addClass('absolute').css('top',downCorner-93);
                }
                else{
                    $('.fix').removeClass('active').removeClass('absolute').css('top','');
                }
            }else{
                if($(document).scrollTop() < $('#detail-car').height()+99-$(window).height()){
                    if($(window).height() > $('#detail-car').height()){
                        $('.fix').addClass('active').css('top','');
                    }else{
                        if($(document).scrollTop() > (99+fixBlockHeight - $(window).height()) ){
                            $('.fix').removeClass('absolute').addClass('active').css('top',-(fixBlockHeight - $(window).height()));
                        }else{
                            $('.fix').removeClass('active').css('top','');
                        }
                    }

                }else{
                    $('.fix').removeClass('active').addClass('absolute').css('top',($('#detail-car').height()-fixBlockHeight));
                }
            }
        }
    });
    /* fix heights */
    resizeUlDetail();
    /* fix heights */
});

$(function() {
  $('select').selectric();
});

function numberFormat(nStr,str,type)
{
    if(type == 1){
        nStr += '';
        x = nStr.split('.');
        x1 = x[0];
        x2 = x.length > 1 ? '.' + x[1] : '';
        var rgx = /(\d+)(\d{3})/;
        while (rgx.test(x1)) {
            x1 = x1.replace(rgx, '$1' + ' ' + '$2');
        }
        return x1 + x2 + " "+ str;
    }else{
        return nStr + " "+ str;
    }
}

function resizeUlDetail(bool){
    $('#showRoom .photo span').css('height',parseInt($('#showRoom .photo img').outerHeight()));
    $('#detail-car .photogalery .photo span').css('height',parseInt($('#detail-car .photogalery .photo img').outerHeight()));
    $('#detail-car .head-photo .photo span').css('height',parseInt($('#detail-car .head-photo .photo img').outerHeight()));
    $('.carBox span.img span').css('height',parseInt($('.carBox span.img img').outerHeight()));

    $('.fix').css('width','');
    $('.fix').removeClass('active').removeClass('absolute').css('top','');

        /* FIX left column detail car*/
        if($(window).width() <= 1249 && $(window).width()>=990){
            if(bool){
                heaightShow = (parseInt($('#showRoom').outerHeight())+1)/2;
                $('#showRoom a.photo').css('height',heaightShow).addClass('responsive');
                $('#showRoom a.photo img').css('height',heaightShow).removeClass('img-responsive');

                $('#showRoom .photo span').css('height',parseInt($('#showRoom .photo img').outerHeight()));
            }
        }else{
            if(bool){
                $('#showRoom a.photo').css('height','').removeClass('responsive');
                $('#showRoom a.photo img').css('height','').addClass('img-responsive');

                $('#showRoom .photo span').css('height',parseInt($('#showRoom .photo img').outerHeight()));
            }
        }




}


function initMap() {
    // Styles a map in night mode.
    var map = new google.maps.Map(document.getElementById('googleMap'), {
        center: {
            lat: 50.065484,
            lng: 14.339538
        },
        zoom: 16,
        disableDefaultUI: false,
        scrollwheel: false,
        styles: [{

                "featureType": "water",
                "elementType": "geometry",
                "stylers": [{
                        "color": "#e9e9e9"
                    },
                    {
                        "lightness": 17
                    }
                ]
            },
            {
                "featureType": "landscape",
                "elementType": "geometry",
                "stylers": [{
                        "color": "#f5f5f5"
                    },
                    {
                        "lightness": 20
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "geometry.fill",
                "stylers": [{
                        "color": "#ffffff"
                    },
                    {
                        "lightness": 17
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "geometry.stroke",
                "stylers": [{
                        "color": "#ffffff"
                    },
                    {
                        "lightness": 29
                    },
                    {
                        "weight": 0.2
                    }
                ]
            },
            {
                "featureType": "road.arterial",
                "elementType": "geometry",
                "stylers": [{
                        "color": "#ffffff"
                    },
                    {
                        "lightness": 18
                    }
                ]
            },
            {
                "featureType": "road.local",
                "elementType": "geometry",
                "stylers": [{
                        "color": "#ffffff"
                    },
                    {
                        "lightness": 16
                    }
                ]
            },
            {
                "featureType": "poi",
                "elementType": "geometry",
                "stylers": [{
                        "color": "#f5f5f5"
                    },
                    {
                        "lightness": 21
                    }
                ]
            },
            {
                "featureType": "poi.park",
                "elementType": "geometry",
                "stylers": [{
                        "color": "#dedede"
                    },
                    {
                        "lightness": 21
                    }
                ]
            },
            {
                "elementType": "labels.text.stroke",
                "stylers": [{
                        "visibility": "on"
                    },
                    {
                        "color": "#ffffff"
                    },
                    {
                        "lightness": 16
                    }
                ]
            },
            {
                "elementType": "labels.text.fill",
                "stylers": [{
                        "saturation": 36
                    },
                    {
                        "color": "#333333"
                    },
                    {
                        "lightness": 40
                    }
                ]
            },
            {
                "elementType": "labels.icon",
                "stylers": [{
                    "visibility": "off"
                }]
            },
            {
                "featureType": "transit",
                "elementType": "geometry",
                "stylers": [{
                        "color": "#f2f2f2"
                    },
                    {
                        "lightness": 19
                    }
                ]
            },
            {
                "featureType": "administrative",
                "elementType": "geometry.fill",
                "stylers": [{
                        "color": "#fefefe"
                    },
                    {
                        "lightness": 20
                    }
                ]
            },
            {
                "featureType": "administrative",
                "elementType": "geometry.stroke",
                "stylers": [{
                        "color": "#fefefe"
                    },
                    {
                        "lightness": 17
                    },
                    {
                        "weight": 1.2
                    }
                ]
            }
        ]
    });
    var image = 'https://www.emotion-cars.cz/public/pictures/map-pin.png';
    var myLatlng = new google.maps.LatLng(50.065498, 14.336252);
    var marker = new google.maps.Marker({
        position: myLatlng,
        animation: google.maps.Animation.DROP,
        title: "Hello World!",
        size: new google.maps.Size(71, 74),
        anchor: new google.maps.Point(0, 74),
        icon: image
    });
    marker.setMap(map);
}

$(function() {
   $(document).on('click', '.megamenu-toggler', function(e) {
       e.preventDefault();

       $(this).toggleClass('megamenu-toggler--open');
       $('.megamenu').toggleClass('megamenu--open');
   })
});